import { StyledSubmitButton } from "components/utils/buttons";
import { StyledHeader, StyledParagraph } from "components/utils/text";
import { Constants } from "config/constants";

const colors = Constants.theme.colors;

export const SuccessBlock = (props) => {
  return (
    <>
      <StyledHeader
        fontSize={"1.5rem"}
        textAlign={"start"}
        color={colors.primary}
      >
        You officially joined the Beta 🚀
      </StyledHeader>
      <StyledParagraph
        margin={"0.8rem 0"}
        textAlign='start'
        color={colors.charcoalGrey}
        fontSize={"1rem"}
      >
        You can join the community now! 🎉 <br />
        We will give you your personal role in the Discord server as soon as
        possible after you joined. <br />
        <br />
        See you there! 👋
      </StyledParagraph>
      <StyledSubmitButton
        onClick={() => {
          props.redirect(process.env.REACT_APP_DC_INVITE);
        }}
      >
        Join Discord
      </StyledSubmitButton>
    </>
  );
};

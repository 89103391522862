import { StyledSubmitButton } from "components/utils/buttons";
import { Card } from "components/utils/cards";
import { Container } from "components/utils/layouts";
import { StyledHeader, StyledParagraph } from "components/utils/text";
import { Constants } from "config/constants";
import { usePlatformContext } from "config/context";
import { useEffect, useState } from "react";

const colors = Constants.theme.colors;

const VerifyEmailBlock = (props) => {
  return (
    <>
      <StyledHeader
        fontSize={"1.5rem"}
        textAlign={"start"}
        color={colors.primary}
      >
        Please verify your Email!
      </StyledHeader>
      <StyledParagraph
        margin={"0.8rem 0"}
        textAlign='start'
        color={colors.charcoalGrey}
        fontSize={"1rem"}
      >
        {!props.resend && props.count > 0
          ? "We've sent you an email with a verification link."
          : "Please click on the button below to recieve a verification link. If you have already recieved the link, please check your spam folder. After you have verified your email, please refresh this page."}
      </StyledParagraph>
      <StyledSubmitButton
        disabled={!props.resend && props.count > 0}
        onClick={() => {
          props.handleSubmit();
        }}
      >
        {props.resend || props.count > 0 ? "Resend Link" : "Send Link"}
      </StyledSubmitButton>
    </>
  );
};

function VerifyEmail() {
  const context = usePlatformContext();
  const [resend, setResend] = useState(true);
  const [count, setCount] = useState(0);

  const handleSubmit = (values) => {
    context.api.verifyEmail().then((data) => {
      setResend(false);
      setCount(count + 1);
    });
  };

  useEffect(() => {
    if (context.user.attribute.isEmailVerified) {
      context.utils.redirect("/");
    }
    if (!resend) {
      const timer = setTimeout(() => {
        setResend(true);
      }, 10000);
      return () => clearTimeout(timer);
    }
  });

  return (
    <Container>
      <Card justifyContent={"space-between"} maxWidth={"600px"}>
        <VerifyEmailBlock
          count={count}
          resend={resend}
          handleSubmit={handleSubmit}
          redirect={context.utils.redirect}
          isEmailVerified={context.user.attribute.isEmailVerified}
        />
      </Card>
    </Container>
  );
}

export default VerifyEmail;

import {
  LayoutContent,
  LayoutFooter,
  LayoutHeader,
  LayoutMain,
  LayoutWrapper,
} from "components/utils/layout.styles";
import { StyledParagraph } from "components/utils/text";
import React from "react";
import { Outlet } from "react-router";
import { Loading } from "components/utils/loading";
import { usePlatformContext } from "config/context";
import { StyledIconButton } from "./utils/buttons";
import { CiLogout } from "react-icons/ci";
import { Logo } from "./utils/logo";

function Layout() {
  const context = usePlatformContext();
  return (
    <LayoutWrapper>
      <LayoutMain>
        <LayoutHeader>
          <Logo />
          <StyledIconButton
            onClick={() => {
              console.log("logout");
              context.api.logout();
            }}
          >
            <CiLogout />
          </StyledIconButton>
        </LayoutHeader>
        <LayoutContent normal={!context.loading}>
          {context.loading ? <Loading /> : <Outlet />}
        </LayoutContent>
        <LayoutFooter>
          <StyledParagraph fontSize={"0.8rem"} color={"#A1A1A1"}>
            This site is protected by reCAPTCHA and the Google Privacy Policy
            and Terms of Service apply.
          </StyledParagraph>
        </LayoutFooter>
      </LayoutMain>
    </LayoutWrapper>
  );
}

export default Layout;

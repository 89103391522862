import styled from "styled-components";
import { Constants } from "config/constants";

const colors = Constants.theme.colors;

export const Card = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width || "100%"};
  min-width: ${(props) => props.minWidth || "200px"};
  max-width: ${(props) => props.maxWidth || "500px"};
  background-color: ${colors.white};
  min-height: ${(props) => props.minHeight || "100px"};
  max-height: ${(props) => props.maxHeight || "auto"};
  border-radius: 10px;
  border: 1px solid ${colors.mediumGrey};
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent || "center"};
`;

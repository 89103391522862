export const dummy = {
  user: {
    attribute: {
      emailConfirmationToken: null,
      reserPaswordToken: {
        token: null,
      },
      isBetaMember: false,
      last_token_refresh: null,
      isEmailVerified: false,
      isActive: false,
      isDeleted: false,
      isBlocked: false,
      isReported: false,
    },
    _id: "",
    Email: "",
    Password: "",
  },
  pageData: {
    name: "@account",
  },
  userData: {
    name: "John Doe",
  },
};

import { StyledSubmitButton } from "components/utils/buttons";
import { StyledHeader, StyledParagraph } from "components/utils/text";
import { Constants } from "config/constants";

const colors = Constants.theme.colors;

export const WelcomeBlock = (props) => {
  return (
    <>
      <StyledHeader
        fontSize={"1.5rem"}
        textAlign={"start"}
        color={colors.primary}
      >
        Welcome to Bestgrowth! 🚀
      </StyledHeader>
      <StyledParagraph
        margin={"0.8rem 0"}
        textAlign='start'
        color={colors.charcoalGrey}
        fontSize={"1rem"}
      >
        We're stoked to have you here, checking out our platform before anyone
        else gets to see it. You're a pioneer, the kind that loves to lead the
        way and push boundaries. <br />
        <br /> We're creating a platform that will revolutionize the way
        promotions are done. Less time chatting, more time influencing. Sounds
        good? That's just the tip of the iceberg.
        <br />
        <br />
        Here's an exclusive chance for you to be a part of this revolution from
        the get-go. Click on the "Pre-Signup for Beta" button to join the ride
        and get VIP access to our platform. This will take you to a quick form,
        and once that's done, we'll usher you into our exclusive Discord server
        where you can connect with other pioneers like you.
        <br />
        <br />
        Remember, the future is what we make of it, and we're thrilled you're
        choosing to make it with us. Let's get this show on the road! 🎉
      </StyledParagraph>
      <StyledSubmitButton
        onClick={() => {
          props.navigate();
        }}
      >
        Pre-Signup for Beta
      </StyledSubmitButton>
    </>
  );
};

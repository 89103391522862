import {
  StyledHeader,
  StyledLink,
  StyledParagraph,
} from "components/utils/text";
import { Constants } from "config/constants";

const colors = Constants.theme.colors;

export const PendingBlock = (props) => {
  return (
    <>
      <StyledHeader
        fontSize={"1.5rem"}
        textAlign={"start"}
        color={colors.primary}
      >
        Nearly there! 🚀
      </StyledHeader>
      <StyledParagraph
        margin={"0.8rem 0"}
        textAlign='start'
        color={colors.charcoalGrey}
        fontSize={"1rem"}
      >
        We have sent you a code to your email address. You will need this code
        to verify your instagram account.
        <br />
        <br />
        Please use <strong>@{props.instagram}</strong> to send us the code to{" "}
        <strong>@bestgrowth.official</strong> on Instagram.
        <br />
        <br />
        See you there! 👋
      </StyledParagraph>
      <StyledLink
        textAlign={"center"}
        onClick={() => {
          props.navigate("redeem");
        }}
      >
        No Code received? Click here
      </StyledLink>
    </>
  );
};

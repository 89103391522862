import Layout from "components/layout";
import NotFound from "pages/NotFound";
import Redirect from "pages/Redirect";
import VerifyEmail from "pages/verify-email";
import Welcome from "pages/welcome";
import { RequireAuth } from "./middleware";
import Admin from "pages/Admin";

export const routes = [
  {
    element: Layout,
    children: [
      {
        element: RequireAuth,
        children: [
          {
            path: "/",
            element: Welcome,
          },
          {
            path: "/verify-email",
            element: VerifyEmail,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: NotFound,
  },
  {
    path: "/login",
    element: Redirect,
  },
  {
    path: "/admin",
    element: Admin,
  },
];

import { PendingBlock } from "components/sections/starting-form/pending";
import { RedeemBlock } from "components/sections/starting-form/redeem";
import { SuccessBlock } from "components/sections/starting-form/success";
import { WelcomeBlock } from "components/sections/starting-form/welcome";
import { Card } from "components/utils/cards";
import { Container } from "components/utils/layouts";
import { usePlatformContext } from "config/context";
import { useState } from "react";

function Welcome() {
  const context = usePlatformContext();
  const [location, setLocation] = useState(
    context.pageData?.attribute ? "pending" : "welcome"
  );
  const navigate = (location) => setLocation(location);
  const [failed, setFailed] = useState(false);

  const handleSubmit = (values) => {
    context.api.accessPreSignup(values).then((data) => {
      if (!data.status) setFailed(true);
      else {
        context.setLoading(true);
      }
    });
  };

  return (
    <Container>
      <Card justifyContent={"space-between"} maxWidth={"600px"}>
        {context.user.attribute.isBetaMember ? (
          <SuccessBlock redirect={context.utils.redirect} />
        ) : location === "redeem" ? (
          <RedeemBlock
            handleSubmit={handleSubmit}
            failed={failed}
            data={{ page: context.pageData, user: context.userData }}
          />
        ) : location === "welcome" ? (
          <WelcomeBlock
            navigate={() => {
              navigate("redeem");
            }}
            redirect={context.utils.redirect}
          />
        ) : (
          <PendingBlock navigate={navigate} instagram={context.pageData.name} />
        )}
      </Card>
    </Container>
  );
}

export default Welcome;

import { useCustomToast } from "hooks/toast";
import React, { useContext, useEffect, useState } from "react";
import { useApi } from "hooks/api";
import { useUtils } from "hooks/utils";
import { dummy } from "./dummy";
import { useNavigate } from "react-router";

const Context = React.createContext();

export const ContextWrapper = ({ children }) => {
  const navigate = useNavigate();
  const { showToast } = useCustomToast();
  const [loading, setLoading] = useState(true);
  const [signedIn, setSignedIn] = useState(true);
  const [user, setUser] = useState(dummy.user);
  const [pageData, setPageData] = useState(dummy.pageData);
  const [userData, setUserData] = useState(dummy.userData);
  const api = useApi({ showToast });
  const utils = useUtils();

  useEffect(() => {
    if (loading) {
      api.checkAdmin().then((data) => {});
      api.resetToken().then((data) => {
        setSignedIn(data.status);
      });
      api.getPageData().then((data) => {
        if (data.status) {
          setPageData(data.data);
        }
      });
      api.getUserData().then((data) => {
        if (data.status) {
          setUserData(data.data);
        }
      });
      api.getUser().then((data) => {
        if (data.status) {
          setUser(data.data);
          setLoading(false);
          if (!data.data.attribute.isEmailVerified) {
            navigate("/verify-email");
          }
        }
      });
    }
  }, [loading, api, user, navigate]);

  const contextData = {
    showToast,
    api,
    loading,
    setLoading,
    utils,
    user,
    signedIn,
    pageData,
    userData,
  };

  return <Context.Provider value={contextData}>{children}</Context.Provider>;
};

export const usePlatformContext = () => {
  return useContext(Context);
};

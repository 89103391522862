import { StyledSubmitButton } from "components/utils/buttons";
import { FormInput, FormWrapper } from "components/utils/form.styles";
import { StyledHeader, StyledParagraph } from "components/utils/text";
import { Constants } from "config/constants";
import { useForm } from "hooks/form-handler";

const colors = Constants.theme.colors;

export const RedeemBlock = (props) => {
  const initialValues = {
    name: props.data.user.UserData?.name || "",
    instagram: props.data.page?.name || "",
  };
  const { handleChange, ready, values, check, setReady, handleClick } = useForm(
    initialValues,
    props.handleSubmit
  );
  return (
    <FormWrapper
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        handleClick();
      }}
    >
      <StyledHeader
        fontSize={"1.5rem"}
        textAlign={"start"}
        color={colors.primary}
      >
        Add your first IG Page! 🚀
      </StyledHeader>
      <StyledParagraph
        margin={"0.8rem 0"}
        textAlign='start'
        color={colors.charcoalGrey}
        fontSize={"1rem"}
      >
        Please enter your name and add your best Instagram accounts name. We
        will send you a code per mail.
      </StyledParagraph>
      <FormInput
        onChange={handleChange}
        ready={ready}
        defaultValue={values.name}
        name='name'
        setReady={setReady}
        check={check}
        placeholder='Enter your full name'
        maxLength={60}
        type='text'
        error={"Please enter your correct name"}
      />
      <FormInput
        onChange={handleChange}
        ready={ready}
        defaultValue={values.instagram}
        name='instagram'
        setReady={setReady}
        check={check}
        placeholder="Enter your best instagram's account name"
        type='text'
        maxLength={30}
        error={"Please enter a correct instagram account name"}
      />
      <StyledSubmitButton margin={"0.8rem 0"} type='submit'>
        {props.failed ? "Retry" : "Redeem now"}
      </StyledSubmitButton>
    </FormWrapper>
  );
};

import { useState } from "react";

export const useForm = (initialValues, handleSubmit) => {
  let getValues = (input) => {
    let output = {};
    Object.keys(input).map((value) => {
      if (input[value] !== "" && input[value] !== null) {
        output[value.toString()] = true;
      } else {
        output[value.toString()] = false;
      }
      return null;
    });
    console.log(output);
    return output;
  };

  let checkValues = (input) => {
    let status = true;
    Object.values(input).map((value) => {
      if (value === false) {
        status = false;
      }
      return null;
    });
    return status;
  };

  const [values, setValues] = useState(initialValues);
  const [ready, setReady] = useState(getValues(initialValues));
  const [check, setCheck] = useState(false);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  function handleClick() {
    setCheck(true);
    if (checkValues(ready)) {
      handleSubmit(values);
    }
  }

  return {
    handleChange,
    handleClick,
    values,
    ready,
    setReady,
    check,
    setCheck,
  };
};
